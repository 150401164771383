#navbar-collapse {
  justify-content: space-between;
}

.navbar {
  background: #262022 !important;
  padding: 2px 0;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.nav-item {
  display: flex;
  align-items: center;
  font-weight: 900;
  text-transform: uppercase;
  padding: 0 0 0 10px;
}

.nav-item svg {
  height: 30px;
  padding: 0;
  margin: 0;
  width: 30px;
}

.nav-item svg path {
  fill: #fff;
}

.nav-item:hover svg path {
  /* fill: #6950d0; */
}

a.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 6px 4px 2px 4px;
  color: #fff;
}

.nav-item button {
  font-size: 1.2rem;
  font-family: "Balsamiq Sans", cursive !important;
  padding: 10px 20px;
  box-sizing: border-box;
  font-weight: 900;
  border: none;
  background: #ed9419;
  border-radius: 3px;
  color: #fff !important;
  cursor: pointer;
  /*transition: all 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);*/
  background-image: linear-gradient(45deg, #ed9419 50%, #262022 50%);
  background-image: linear-gradient(45deg, #fff 50%, #ed9419 50%);
  background-position: 100%;
  background-size: 250%;
}

@media (hover: hover) {
  a.nav-link:hover {
    background: #ed9419;
    color: #fff !important;
  }
}

button.navbar-toggler:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

a.mint-btn-nav {
  color: #000;
}

.navbar-brand,
.navbar-brand:hover {
  color: #ed9419;
  font-weight: 900;
}
.navbar-brand img {
  max-height: 60px;
}

.navbar-toggler svg {
  width: 30px;
}

.navbar-toggler svg path {
  fill: #fff;
}

.mint-btn-nav {
  font-size: 1.2rem;
  padding: 7px 36px 2px 36px;
  font-family: "Balsamiq Sans", sans-serif;
  font-weight: 900;
  margin: 10px 0 10px 0;
  box-sizing: border-box;
  background: #000;
  color: #fff !important;
  border: none;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.mint-btn-nav:focus {
  border: none;
}

@media (hover: hover) {
  .mint-btn-nav:hover {
    background: #000;
  }
}

.socials svg {
  height: 30px;
  width: 30px;
}

.fsocials svg {
  height: 50px;
  width: 50px;
  cursor: pointer;
  margin: 0px 5px;
}

.team-card path {
  fill: #ed9419;
}

.socials {
  float: right;
  cursor: pointer;
}

.transparent {
  background: rgba(105, 80, 208, 0) !important;
}
.opaque {
  background: rgba(105, 80, 208, 1) !important;
}

@media (max-width: 768px) {
  .navbar-collapse {
    display: inline-block;
  }
}
