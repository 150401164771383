@import url("https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Vampiro+One&display=swap");

/* 
purple  6950D0
black   262022 
orange  ED9419
*/

li,
button,
input,
.App,
div,
span,
h1,
h2,
h3 {
  font-family: "Balsamiq Sans", sans-serif;
}

p {
  font-family: "Ubuntu", sans-serif;
  font-size: 1.15rem;
}

h1 {
  font-family: "Balsamiq Sans", sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0 0 40px 0;
}

.App {
  background: #cb2615;
  color: #fff;
  text-align: center;
  min-height: 100vh;
  box-sizing: border-box;
}

.heroWrapper {
  background: #e74c3c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.midTitle {
  font-family: "Vampiro One", cursive;
  margin-bottom: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 166.4%;
  text-align: center;
  color: #ffffff;
}

.sidebar {
  padding: 20px 0;
  width: 220px;
  min-height: 100vh;
  box-sizing: border-box;
  background: #112;
}

.main {
  background: #224;
  padding: 20px 0;
  min-height: 100vh;
  box-sizing: border-box;
}

.display {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 10px 0;
}

.display .label {
  flex: 0 0 100%;
  font-size: 12px;
}

.display .value {
  margin: auto;
  font-size: 14px;
  padding: 2px 10px;
  background: #446;
  border-radius: 3px;
}

.token-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.token {
  margin: 3px;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

@media (hover: hover) {
  .token:hover {
    transform: scale(1.1);
  }
}

.token img {
  width: 150px;
}

.mint-row {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  /* max-width: 500px; */
}

.mint-row #mint-amount {
  min-width: 70px;
  text-align: center;
  position: relative;
  top: 3px;
}

.mint-bottom-row {
  display: inline-block;
  width: 350px;
  margin: 0 auto;
}

.dropdown {
  display: inline;
}
.mint-btn {
  font-size: 2rem;
  font-family: "Balsamiq Sans", cursive !important;
  height: 60px;
  padding: 0;
  width: 60%;
  max-width: 500px;
  margin: 0 10px 0 0;
  box-sizing: border-box;
  font-weight: 900;
  border: none;
  background: #ed9419;
  padding: 6px 0 0 0;
  border-radius: 3px;
  /* border: 5px solid #fff; */
  color: #fff !important;
  transition: all 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  color: #fff;
  background-image: linear-gradient(45deg, #ed9419 50%, #262022 50%);
  background-image: linear-gradient(45deg, #fff 50%, #ed9419 50%);
  background-position: 100%;
  background-size: 250%;
}

@media (hover: hover) {
  .mint-btn:hover {
    /* border: 5px solid #ED9419; */
    border: none;
    /* border: 5px solid #000; */
    outline: none;
    /* text-shadow: 0 0 10px rgba(0,0,0,.3); */
    background-position: 0;
    transform: scale(1.04);
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.6);
    color: #ed9419 !important;
  }
}

#mint-amount {
  font-size: 1.4rem;
  font-family: "Balsamiq Sans", sans-serif;
  /* padding: 5px 5px 5px 20px; */
  text-align: center;
  /* width: 100%; */
  display: inline-block;
  height: 60px;
  box-sizing: border-box;
  font-weight: 900;
  outline: none;
  border: none;
  margin: 0 0 0 0px;
  background: #ed9419;
  border-radius: 3px;
  /* border: 5px solid #ED9419; */
  color: #fff !important;
  transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);
  background-image: linear-gradient(45deg, #ed9419 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
}

.btn:focus {
  background: #fff;
  color: #262022 !important;
  border: 5px solid #fff;
  box-shadow: none;
}
.btn:active {
  background: #ed9419;
  color: #262022 !important;
  border: 5px solid #ed9419;
  box-shadow: none !important;
}

.cost {
  display: flex;
  width: 120px;
  align-items: center;
  justify-content: center;
  background: #f2a;
  border-radius: 5px;
  font-size: 1.4rem;
  padding: 5px 0 0 0;
  height: 40px;
  margin: 0;
}

.hero-minted {
  font-size: 1.5rem;
  margin: 60px 0 0 0;
  line-height: 1rem;
}

.supply {
  background: #262022;
  border-radius: 5px;
  font-size: 2rem;
  padding: 6px 12px;
  margin: 0px 10px;
}

#hero {
  background: #cb2615;
  min-height: 100vh;
  padding: 80px 0 0 0;
}

.lead {
  font-size: 1rem;
}

section {
  padding: 80px 0 100px 0;
}

.traits-row {
  margin: 60px 0 0 0;
  background: #fcfcfc;
  padding: 90px 50px;
  border-radius: 5px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
}

.traits-row h1 {
  color: #111;
}

.traits-row p {
  color: #111;
}

#colors h1 {
  margin: 0 0 50px 0;
}

#colors p {
  font-size: 1.1rem;
}

.img-wrapper {
  /* background: #fff; */
  padding: 0;
  margin: 0px;
  border-radius: 14px;
  overflow: hidden;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); */
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}

#rarity {
  color: #fff;
  background: #262022;
}

#merch {
  color: #fff;
  background: #262022;
}

.team-crimes {
  padding: 10px 0 0 0;
  margin: 10px 0 0 0;
  width: 100%;
  border-top: 2px solid #e5e5e5;
}

.team-wanted {
  font-weight: 900;
  font-size: 1.5rem;
  margin: 0 0 0px 0;
  margin-top: 35px;
  margin-bottom: 25px;
}

.team-wanted::before {
  content: " - ";
}
.team-wanted::after {
  content: " - ";
}

a {
  text-decoration: none;
  color: #fff;
}

a:hover {
  color: #fff;
}

.team-card {
  background: #262022;
  padding: 13px 0;
  border-radius: 10px;
  margin: 10px 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.team-card:hover {
  transform: scale(1.02);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
}
.team-card div {
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  text-decoration: none;
}

.team-card:hover div {
  color: #fff;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.9);
  text-decoration: none;
}
.team-name {
  color: #ed9419;
  flex: 0 0 100%;
  font-size: 2rem;
  font-weight: 900;
  width: 100%;
  margin: 0;
}

.team-description {
  font-size: 1rem;
  flex: 0 0 100%;
  padding: 12px 0 0px 0;
  border-top: 2px solid #e5e5e5;
  width: 100%;
}

#FAQ h1 {
  color: #fff;
}

#accordion-container {
  color: #111;
  max-width: 800px;
  margin: 0 auto;
  background: none;
}
.accordion-header button {
  font-size: 1.3rem;
}

.accordion-item {
  outline: none;
  border: none;
  color: #fff;
  margin: 2px 0 0 0;
  background: none;
}

.accordion-button {
  border-radius: 5px !important;
  background: #262022;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
}

.accordion-button:focus {
  color: #fff;
  background: #262022;

  outline: none !important;
  border: none !important;
}

.accordion {
  background: none;
}

.accordion-button:not(.collapsed) {
  background: #f0c326;
  color: #262022;
  border: none !important;
  outline: none !important;
}

.navbar-toggler {
  border: none !important;
  outline: none !important;
}

footer {
  padding: 20px 0;
  background: #262022;
  text-align: right;
}

.socials {
  display: inline;
  padding: 10px;
}
.footer-title {
  text-align: left;
}

.roadmap-description {
  text-align: left;
}

.roadmap-number {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e74c3c;
  width: 110px;
  text-align: center;
  padding: 10px;
  margin: 0px;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(187, 187, 187, 0.3);
  font-weight: 900;
  font-size: 1.8rem;
}

.roadmap-item {
  width: 100%;
  margin: 15px 0;
  text-align: left;
}

.roadmap-title {
  padding: 20px 0 20px 40px;
  margin: 10px 0px 0 0;
  font-weight: 700;
  font-size: 1.2rem;
}

.showcase {
  padding: 0;
  margin: 0;
}
.showcase img {
  /* border-top: 7px solid #262022;
  border-left: 4px solid #262022;
  border-right: 4px solid #262022;
  border-bottom: 7px solid #262022; */
  width: 100%;
  margin: 0;
  padding: 0px 12px 33px;
}

.showcase .row {
  --bs-gutter-x: 0;
}

#about {
  color: #fff;
  background: #262022 !important;
  text-align: center;
  box-sizing: border-box;
}

.section-title svg {
  max-height: 120px;
}

.section-title path {
  fill: #fff;
}

.section-title {
  display: flex;
  width: 100%;
  text-align: center;
  margin: 0 auto 30px auto;
}

#rarity p {
  text-align: left;
}

.section-title img {
  max-width: 80%;
}

.section-title.bun {
  width: 100%;
}

.section-title.bun img {
  max-width: 90%;
}

.countdown {
  margin: auto;
}
.countdown div {
  /* background: #262022; */
  color: #fff;
  max-width: 110px;
  margin: 0 5px 30px 5px;
  font-weight: 900;
  font-size: 2rem;
  line-height: 2rem;
  padding: 4px 0;
  border-radius: 5px;
}

.countdown .label {
  font-size: 1.2rem;
  font-weight: 400;
}

/* VIDEO BACKGROUND */
.videoBackground {
  position: relative;
  background-color: black;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
}

.videoBackground > video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.videoBackground .container {
  position: relative;
  z-index: 2;
}

.videoBackground .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}

.rarity-special {
  /* max-height: 400px; */
  margin: 0 0 50px 0;
}

#rarity p.rarity-name {
  font-family: "Balsamiq Sans", sans-serif;
  margin: 7px auto 10px 0;
  text-align: center;
  font-weight: 900;
  font-size: 1.3rem;
  width: 100%;
}

section p {
  text-align: left;
}

section#about p {
  text-align: center;
}

#charity .section-title {
  justify-content: center;
}

#charity p {
  text-align: center;
}

#charity a {
  /* background: #ED9419; */
  text-decoration: underline;
  color: #ed9419;
  border-radius: 5px;
  transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
}
#charity a:hover {
  color: #fff;
  /* background: #fff; */
}

video {
  height: 50vh;
  margin-bottom: 20px;
}

.hero-container {
  display: flex;
  flex-wrap: wrap;
  align-items: space-between !important;
  align-content: space-between !important;
  justify-content: space-between !important;
}
.hero-container .row {
  flex: 0 0 100%;
}

.mint-box {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.mint-box .row {
  flex: 0 0 100%;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background: #444 !important;
}
#mint-amount.disabled {
  background: #444 !important;
  opacity: 0.65;
}

.svg {
  max-height: 100px;
  margin: 0 0 30px 0;
  max-width: 100% !important;
}

svg {
  max-width: 100%;
}

.svg-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
}

@media (max-width: 768px) {
  .hero-minted {
    font-size: 1.1rem;
    margin: 25px 0px;
  }

  .heroWrapper {
    margin-bottom: 25px;
  }

  video {
    max-height: 40vh;
  }
  .navbar-brand img {
    max-height: 45px !important;
  }
  #hero {
    padding: 20px 0 0 0;
  }
  .countdown .number {
    font-size: 1.2rem;
  }
  .countdown .label {
    font-size: 1rem;
  }
  .countdown div {
    line-height: 1.4rem;
  }
  .hero-minted .supply {
    font-size: 1.2rem !important;
  }
  .section-title {
    justify-content: center;
  }
  .rarity-name {
    font-size: 1.2rem;
    height: 40px;
  }
  .team-name {
    font-size: 1.1rem;
  }
  .roadmap-title {
    font-size: 1.2rem !important;
  }
  .nav-item {
    margin: auto;
  }
  /* .mint-btn, .mint-bottom-row {
    width: 250px;
  } */
  section {
    padding: 40px 0 40px 0;
  }
  .traits-row {
    padding: 20px 10px;
  }
  h1 {
    font-size: 2.2rem;
  }
  .footer-title {
    display: none;
  }
  footer {
    text-align: center;
  }

  .roadmap-number {
    font-size: 1.2rem;
    width: 70px;
  }
  .svg-box {
    justify-content: center;
  }
  .mint-btn,
  #mint-amount {
    font-size: 1.6rem;
  }
}
@media (max-width: 576px) {
  html {
    font-size: 13px;
  }
}

hr {
  margin: 0 0 10px 0;
  color: #fff;
  background: #fff;
  border: 2px solid #fff;
  width: 100%;
}

#toast {
  z-index: 99999 !important;
}
.toast-header {
  background: #d02;
  color: #fff;
}
.toast-body {
  background: #eee;
  color: #222;
}
